.login-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-icon {
    height: 100%;
    background-color: #FAFAFA;
    display: flex;
    align-items: center;
}

.login-icon svg {
    width: 40%;
    margin: 0 auto;
}

.login-title {
    font-weight: 300;
    font-size: 40px;
    line-height: 47px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.15px;
    color: #37474F;
}

.login-card {
    flex-basis:1;
    margin: 0 auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.login-card > * {
    margin: 8px !important;
}

.poweredBy {
    display: flex;
    flex-direction: column;
    margin-top: 64px !important;
}

.poweredBy svg {
    margin: 0 auto;
}

.poweredBy label {
    width: 100%;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    color: #BDBEBF;
}