.MuiTypography-h1 {
    font-size: 60px;
}

.dash-container {
   display: flex;
   flex-direction: row;
   margin-top: 70px;
   height: calc(100% - 80px);
   overflow: auto;
}

.dashboard {
   flex: 1;
   display: grid;
   margin: 6px;
   gap: 4px;
   grid-template:
      "ind_tarefas_pendentes     ind_sustentacao         ind_sustentacao   ind_evolucao            ind_outros              recorrencia       "    28%
      "tipo_demanda              burndown                burndown          historico_estoque    historico_estoque       sla               "    40%
      "emergencias_executadas    emergencias             emergencias       emergencias          emergencias             emergencias       "    32%
      / 0.6fr                    0.5fr                   0.5fr             0.5fr                0.5fr                   0.6fr             ;
}

.all {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: 9999;
}

.all-success, .all-good {
   height: 100%;
   background: #d2ff52;
   background: -moz-radial-gradient(center, ellipse cover, #d2ff52 0%, #91e842 100%);
   background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #d2ff52), color-stop(100%, #91e842));
   background: -webkit-radial-gradient(center, ellipse cover, #d2ff52 0%, #91e842 100%);
   background: -o-radial-gradient(center, ellipse cover, #d2ff52 0%, #91e842 100%);
   background: -ms-radial-gradient(center, ellipse cover, #d2ff52 0%, #91e842 100%);
   background: radial-gradient(ellipse at center, #d2ff52 0%, #91e842 100%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d2ff52', endColorstr='#91e842', GradientType=1 );
}

.all-danger, .all-bad {
   height: 100%;
   background: #a90329;
   background: -moz-radial-gradient(center, ellipse cover, #a90329 0%, #8f0222 44%, #6d0019 100%);
   background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, #a90329), color-stop(44%, #8f0222), color-stop(100%, #6d0019));
   background: -webkit-radial-gradient(center, ellipse cover, #a90329 0%, #8f0222 44%, #6d0019 100%);
   background: -o-radial-gradient(center, ellipse cover, #a90329 0%, #8f0222 44%, #6d0019 100%);
   background: -ms-radial-gradient(center, ellipse cover, #a90329 0%, #8f0222 44%, #6d0019 100%);
   background: radial-gradient(ellipse at center, #a90329 0%, #8f0222 44%, #6d0019 100%);
   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#6d0019', GradientType=1 );
}

.animationText {
   position: absolute;
   width: 100%;
   text-align: center;
   font-weight: bold;
   font-size: 48px;
   font-family: Arial, sans-serif;
   top: calc(78%);
   color: #FFFFFF;
}

.animation-layer {
   position: absolute;
   z-index: 99999;
   width: 512px;
   height: 512px;
   top: calc(50% - 256px);
   left: calc(50% - 256px);
   pointer-events: none;
}

.onlyMobile {
   display: none !important;
}

.tableRecursos {
   width: 100%;
}
   
@media (max-width: 976px) {
   .dash-container {
      flex-direction: column-reverse;
   }
   .dashboard {
      display: grid;
      margin: 6px;
      grid-template:
         "ind_tarefas_pendentes     ind_tarefas_pendentes"
         "ind_sustentacao           ind_sustentacao"
         "ind_evolucao              ind_evolucao"
         "ind_outros                ind_outros"
         "recorrencia               recorrencia"
         "tipo_demanda              tipo_demanda"
         "burndown                  burndown"
         "historico_estoque         historico_estoque"
         "sla                       sla"
         "emergencias               emergencias"
         "emergencias_executadas    emergencias_executadas"
         / 1fr 1fr;
   }
   .noMobile {
      display: none !important;
   }
   .onlyMobile {
      display: flex !important;
   }
   .recursos {
      flex: 1;
      width: unset !important;
   }
}

.recursos {
   margin: 6px;
   width: 156px;
   transition: all 0.4s ease-in-out;
}
.recursos.open {
   width: 472px;
}

#ind_tarefas_pendentes {
    grid-area: ind_tarefas_pendentes;
}

#ind_estoque {
    grid-area: ind_estoque;
}

#ind_sustentacao {
    grid-area: ind_sustentacao;
}

#ind_evolucao {
    grid-area: ind_evolucao;
}

#ind_outros {
    grid-area: ind_outros;
}

/*#ind_estoque {
    grid-area: ind_estoque;
}*/

#ind_projetos {
    grid-area: ind_projetos;
}

#burndown {
   grid-area: burndown;
}

#historico_estoque {
   grid-area: historico_estoque;
}

#sla {
    grid-area: sla;
}

#recorrencia {
   grid-area: recorrencia;
}

#tipo_demanda {
    grid-area: tipo_demanda;
}

#emergencias {
    grid-area: emergencias;
}

#emergencias_executadas {
    grid-area: emergencias_executadas;
}

.popover {
    overflow-x: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.icon-white svg {
   fill: white;
}

.recurso-foto {
   height: 32px;
   width: 32px;
   object-fit: cover;
   border-radius: 50%;
   pointer-events: none;
}

.recurso-iniciais {
   color: white;
   font-weight: bold;
   text-shadow: -0.5px 0 rgba(0,0,0,0.09), 0 0.5px rgba(0,0,0,0.09), 0.5px 0 rgba(0,0,0,0.09), 0 -0.5px rgba(0,0,0,0.09);
   pointer-events: none;
}

.mixed-chart {
   width: 100%;
}